<template>
  <div class="box">
    <div class="box_left">
      <div class="data_summary">
        <div class="top">
          <div class="top_left">
            <div class="tips_title">
              <div class="line"></div>
              <div class="title">数据汇总</div>
            </div>
            <!--<div class="option">-->
            <!--  <div class="title" :class="routeData ? '' : 'color1'">-->
            <!--    文旅数据-->
            <!--  </div>-->
            <!--  <div class="title" :class="routeData ? 'color1' : ''">-->
            <!--    线路数据-->
            <!--  </div>-->
            <!--</div>-->
          </div>
          <div class="top_right">统计时间：{{ time }}</div>
        </div>
        <div class="data_summary_content">
          <div
            v-for="(item, index) in dataSummary"
            :key="index"
            class="list_style"
          >
            <div class="left">
              <div class="text">{{ item.num }}</div>
              <div class="text2">{{ item.title }}</div>
            </div>
            <div class="right">
              <img :src="require(`../../assets/dataStatistics/${4 + index}.png`)"
              />
            </div>
          </div>
        </div>
        <div class="stay">
          <div class="tips_title">
            <div class="line"></div>
            <div class="title">待办事项</div>
          </div>
          <div class="conten">
            <div
              v-for="(item, index) in stayList"
              :key="index"
              class="list_style"
            >
              <div class="left">
                <div class="text">{{ item.num }}</div>
                <div class="text2">{{ item.title }}</div>
              </div>
              <div class="right">
                <img
                  :src="require(`../../assets/dataStatistics/${8 + index}.png`)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order_amount">
        <div class="top">
          <div class="tips_title">
            <div class="line"></div>
            <div class="title">订单金额统计</div>
          </div>
          <div class="calendar">
            <!-- <div class="title">{{ year }}-{{ year2 }}</div> -->
            <el-date-picker :editable="false" v-model="year" type="year" placeholder="选择年份" @change="selectYear($event)"></el-date-picker>
            <!-- <div class="title">{{year}}-{{year+1}}</div> -->
          </div>
        </div>
        <div class="echarts" id="main"></div>
      </div>
    </div>
    <div class="box_right">
      <div class="shop">
        <div class="tips_title">
          <div class="line"></div>
          <div class="title">店铺信息</div>
        </div>
        <div class="middle">
          <div class="left">
            <div class="imgs">
              <img :src="info.homePage" style="width: 100%;height: 100%;border-radius: 50%;" alt="">
            </div>
            <div class="detail">
              <div>{{ info.name }}</div>
              <div>营地账号：{{ info.userAccount }}</div>
            </div>
          </div>
          <!--<div class="right" @click="godetailSet">营地信息设置</div>-->
        </div>
        <div class="word">营地地址：{{ info.address }}</div>
        <div class="word">营地电话：
        <span v-for="(item,index) in phone" :key="index">
          {{ item }}
        </span>
        </div>
      </div>
      <div class="ranking">
        <div class="top">
          <div class="title">产品排名</div>
          <div class="option_box">
            <div :class="rankingType ? '' : 'color1'" @click="seeRank(0)">
              本周
            </div>
            <div :class="rankingType ? 'color1' : ''" @click="seeRank(1)">
              本月
            </div>
          </div>
        </div>
        <div class="content">
          <div class="header">
            <div class="th">排名</div>
            <div class="th2">产品名称</div>
            <div class="th3">下单数量</div>
            <div class="th4">金额（元）</div>
          </div>
          <div class="list_content">
            <div class="list" v-for="(item, index) in rankingList" :key="index">
              <div class="th" v-if="index < 3">
                <img
                  :src="require(`../../assets/dataStatistics/${index + 1}.png`)"
                  alt=""
                />
              </div>
              <div class="th" v-else>
                <div class="bg">{{ index + 1 }}</div>
              </div>
              <div class="th2">{{ item.name }}</div>
              <div class="th3">{{ item.amount }}</div>
              <div class="th4">{{ item.price }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getRanking, getOrderAmount,getDataSummary,getStay,getCampsiteInfo,getUserInfo} from "../../api/dataStatistics.js";

export default {
  data() {
    return {
      time: "",
      dataSummary: [
        {
          num: 0,
          title: "今日新增订单",
        },
        {
          num: 0,
          title: "总订单数量",
        },
        {
          num: 0,
          title: "今日订单金额(元）",
        },
        {
          num: 0,
          title: "订单金额总计(元）",
        },
      ],
      stayList: [
        {
          num: 0,
          title: "待接单数量",
        },
        {
          num: 0,
          title: "今日预离",
        },
        {
          num: 0,
          title: "待入住订单",
        },
        {
          num: 0,
          title: "退款待处理订单",
        },
      ],
      year: new Date(),
      year2: "",
      rankingList: [],
      routeData: false,
      rankingType: 0,
      pid: 'cf436a82-84a6-4e93-9da4-8e8e7ae33a6e',
      info: {},
      phone: [],
      defaultYear:''
    };
  },
  mounted() {
    let Dates = new Date()
    // this.$set(this, 'year', Dates.getFullYear())
    this.defaultYear = Dates
    this.getInfo()
    this.orderAmount(Dates.getFullYear())
    this.rankingData();
    this.DataSummary()
    this.stayData()
  },
  methods: {
    async getInfo() {
      // const res = await getCampsiteInfo(this.pid)
      // if (res.ResultCode === 0) {
      //   this.info = res.Data
      // }
      const res = await getUserInfo()
      if (res.ResultCode === 0) {
        console.log('用户信息', res)
        this.getCampInfo(res.user.campsiteId)
      }
    },
    async getCampInfo(campId) {
      const res = await getCampsiteInfo(campId)
      if (res.ResultCode === 0) {
        console.log(res, '店铺信息')
        if (res.Data.homePage !== null && res.Data.homePage !== undefined && res.Data.homePage !== ''){
          let banner = res.Data.homePage.split(',')
          let banner2 = banner.filter(item => item !== '')
          res.Data.homePage = 'http://yy-camping-hz.oss-cn-hangzhou.aliyuncs.com'+banner2[0]
        }
        try {
          this.phone = JSON.parse(res.Data.phone)
        }catch (e) {
          console.log(e)
        }
        this.info = res.Data
      }
    },
    //获取待办事项
    async stayData(){
      let that = this
      let res = await getStay()
      if(res.ResultCode == 0){
        console.log(res.Data,'res')
        that.stayList.forEach((tmp,index)=>{
          tmp.num = res.Data[index+1]
        })
      }
    },
    //获取数据汇总
    async DataSummary(){
      let that = this
      let res = await getDataSummary()
      if(res.ResultCode == 0){
        console.log(res.Data,'res')
        that.time = res.Data[0]
        that.dataSummary.forEach((tmp,index)=>{
          tmp.num = res.Data[index+1]
        })
      }
    },
    //获取折线图数据
    async orderAmount(year){
      let res = await getOrderAmount({
        year:year
      })
      if(res.ResultCode == 0){
        let list = []
        for(let i in res.Data){
          list[i-1] = res.Data[i]
        }
        this.makingDrawings(list);
      }
    },
    //查看月份或者周的排名
    seeRank(index) {
      this.rankingType = index;
      this.rankingData();
    },
    //修改年份
    selectYear(e){
      this.year=e
      this.orderAmount(e.getFullYear())
    },
    //获取排行数据
    async rankingData() {
      let _week = "";
      let that = this;
      if (that.rankingType) {
        _week = "month";
      } else {
        _week = "week";
      }
      let res = await getRanking({
        my: _week,
      });
      if (res.ResultCode == 0) {
        that.rankingList = res.Data;
      }
    },
    godetailSet() {
      window.open('http://testapi.yunyouzhihui.com/start/index.html#/yylb/yylb_campsite_merchant/add','_black')
    },
    //制作绘图
    makingDrawings(dataList) {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["一月", "二月", "三月", "四月", "五月", "六月", "七月","八月", "九月", "十月", "十一月", "十二月"],
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              // color: '#912CEE'
            },
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: dataList,
            type: "line",

            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#348DF0",
                    },
                    {
                      offset: 1,
                      color: "rgba(126,171,233,0)",
                    },
                  ],
                  false
                ),
                // shadowColor: "rgba(44,118,222, 0.8)",
                // shadowBlur: 20,
              },
            },
            symbolSize: 8,
            lineStyle: {
              width: 1, // 设置线宽
              type: "solid", //'dotted'虚线 'solid'实线
            },
          },
        ],
        color: ["#348DF0"],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 60.13rem;
  background: #f5f6f7;
  display: flex;
  .tips_title {
    display: flex;
    align-items: center;
    .line {
      width: 0.31rem;
      height: 1.25rem;
      background: #1492ff;
      margin-right: 0.81rem;
    }
    .title {
      font-size: 1.38rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
    }
  }
  .box_left {
    flex: 1.3;
    .data_summary {
      box-sizing: border-box;
      background: #ffffff;
      margin-left: 1.19rem;
      margin-top: 1.25rem;
      width: 100%;
      height: 25.13rem;
      padding-top: 2rem;
      padding-left: 1.62rem;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top_left {
          display: flex;
          align-items: center;
          .option {
            margin-left: 2.25rem;
            .title {
              font-size: 1.13rem;
              font-family: PingFang SC;
              font-weight: 500;
              display: inline-block;
              margin: 0 0.875rem;
              cursor: pointer;
            }
          }
        }
        .top_right {
          margin-right: 1.31rem;
          font-size: 1.13rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
        }
      }
      .data_summary_content {
        display: flex;
        justify-content: space-around;
        .list_style {
          // flex: 1;
          width: 14.25rem;
          height: 5.75rem;
          background: #f3f9ff;
          border: 1px solid #1492ff;
          border-radius: 1rem;
          margin-top: 2.25rem;
          // margin-bottom: 2.63rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            margin-left: 1.25rem;
            .text {
              font-size: 1.75rem;
              font-family: DIN Alternate;
              font-weight: bold;
              color: #333333;
            }
            .text2 {
              font-size: 1rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
              // margin-top: 0.75rem;
            }
          }
          .right {
            width: 3.25rem;
            height: 3.25rem;
            margin-right: 1.13rem;
            img {
              width: 3.25rem;
              height: 3.25rem;
            }
          }
        }
      }
      .stay {
        margin-top: 2.63rem;
        .conten {
          display: flex;
          justify-content: space-around;
          .list_style {
            // flex: 1;
            width: 14.25rem;
            height: 5.75rem;
            background: #f3f9ff;
            border: 1px solid #1492ff;
            border-radius: 1rem;
            margin-top: 1.5rem;
            // margin-bottom: 2.63rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              margin-left: 1.25rem;
              .text {
                font-size: 1.75rem;
                font-family: DIN Alternate;
                font-weight: bold;
                color: #333333;
              }
              .text2 {
                font-size: 1rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #666666;
                // margin-top: 0.75rem;
              }
            }
            .right {
              width: 3.25rem;
              height: 3.25rem;
              margin-right: 1.13rem;
              img {
                width: 3.25rem;
                height: 3.25rem;
              }
            }
          }
        }
      }
    }
    .order_amount {
      box-sizing: border-box;
      width: 100%;
      height: 31.19rem;
      background: #ffffff;
      margin-left: 1.19rem;
      padding-left: 1.62rem;
      padding-top: 1.88rem;
      margin-top: 1.25rem;
      .echarts {
        width: 100%;
        height: 28rem;
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .calendar {
          width: 17.44rem;
          height: 2.13rem;
          border: 1px solid #cccccc;
          border-radius: 4px;
          margin-right: 1.5rem;
          position: relative;
          background-image: url('../../assets/dataStatistics/12.png');
          background-size: 1.5rem 1.5rem;
          background-repeat: no-repeat;
          background-position-y: 0.25rem;
          background-position-x: 15.5rem;
          // display: flex;
          // justify-content: space-between;
          // align-items: center;
          .title {
            margin-left: 0.75rem;
            font-size: 1.13rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            position: absolute;
            top: 0.25rem;
            background: #ffffff;
          }
        }
      }
    }
  }
  .box_right {
    flex: 0.7;
    margin-left: 2.19rem;
    margin-top: 1.25rem;
    .shop {
      width: 100%;
      height: 18.13rem;
      background: #ffffff;
      box-sizing: border-box;
      padding-left: 1.63rem;
      padding-top: 2rem;
      .middle {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 2.69rem;
        margin-bottom: 2.19rem;
        .left {
          display: flex;
          .imgs {
            width: 4.13rem;
            height: 4.13rem;
            background: #348df0;
            border-radius: 50%;
          }
          .detail {
            font-family: PingFang SC;
            margin-left: 1.6rem;
            div {
              &:first-child {
                margin-top: 0.5rem;
                font-size: 1.13rem;
                font-weight: bold;
                color: #333333;
              }
              &:nth-child(2) {
                margin-top: 1.13rem;
                font-size: 1.13rem;
                font-weight: 500;
                color: #999999;
              }
            }
          }
        }
        .right {
          width: 6.88rem;
          height: 2.38rem;
          background: #348df0;
          border-radius: 0.25rem;
          margin-right: 1.31rem;
          font-size: 1rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #f3f3f3;
          line-height: 2.38rem;
          text-align: center;
        }
      }
      .word {
        font-size: 1rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        margin-top: 1.19rem;
      }
    }
    .ranking {
      box-sizing: border-box;
      margin-top: 1.25rem;
      padding-top: 2.19rem;
      width: 100%;
      height: 38.19rem;
      background: #ffffff;
      .top {
        display: flex;
        font-size: 1.13rem;
        font-family: PingFang SC;
        font-weight: 500;
        .title {
          margin-left: 1.31rem;
        }
        .option_box {
          margin-left: 1.63rem;
          div {
            display: inline-block;
            margin: 0 1rem;
            cursor: pointer;
          }
        }
      }
      .content {
        width: 100%;
        box-sizing: border-box;
        padding: 0 0.63rem;
        margin-top: 2.5rem;
        .header {
          background: #f3f9ff;
        }
        .header,
        .list {
          width: 100%;
          height: 3.75rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            text-align: center;
          }
          .th {
            flex: 0.7;
            img {
              width: 1.63rem;
              height: 1.63rem;
            }
          }
          .bg {
            width: 1.63rem;
            height: 1.63rem;
            background: #2ec0de;
            border-radius: 50%;
            font-size: 1rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #fefefe;
            margin: 0 auto;
            text-align: center;
            line-height: 1.63rem;
          }
          .th2 {
            flex: 1.1;
          }
          .th3 {
            flex: 1.1;
          }
          .th4 {
            flex: 1.1;
          }
        }
        .list_content {
          width: 100%;
          height: 28.63rem;
          overflow-y: auto;
        }
      }
    }
  }
}
.color1 {
  color: #348df0;
}
.calendar ::v-deep .el-input__inner{
  border: none !important;
  height: 2.13rem !important;
  background-color: rgba($color: #000000, $alpha: 0);
  width: 100% !important;
  cursor: pointer !important
}
.calendar ::v-deep .el-input__icon{
  display: none !important;
}
.calendar ::v-deep .el-date-editor.el-input{
  width: 100% !important;
}
</style>
